import client from "../index"

export interface BatchesWithBatchData {
  id: string
  mobileNumber: string
  tokenId: string
  status: string
  reason: string
  sentAt: string
  createdAt: string
  batchId: string
  batch: Batch
}

export interface Batch {
  id: string
  maxSupply: number
  startToken: number
  nftName: string
  mediaPreview: string
  remark: string
  description: string
  properties: any
  confirmedAt: string
  scheduledDate: string
  createdBy: any
  createdAt: string
  updatedAt: string
  collectionAddress: string
}

export interface Collection {
  address: string
  symbol: string
  name: string
  maxSupply: string
  baseURI: string
  owner: string
  ownerName: string
  transactionHash: string
  contractLevelMetadataURI: string
  createdAt: string
  dropType: string
}

export interface BatchData {
  id: string
  mobileNumber: string
  tokenId: string
  status: string
  reason: string
  sentAt: string
  createdAt: string
}

export interface BatchDataWithBatch extends BatchData {
  batch: Batch
}

async function getCollection(address: string): Promise<Collection> {
  const res = await client.get(`/collections/${address}`)
  return res.data
}

async function getBatches(
  collectionAddress: string,
  orderBy = "ASC",
  sortBy = "createdAt"
): Promise<Batch[]> {
  const res = await client.get("/batches", {
    params: {
      collectionAddress,
      sortBy,
      orderBy,
    }
  })
  return res.data
}

async function getBatchById(id: string): Promise<Batch> {
  const res = await client.get(`/batches/${id}`)
  return res.data
}

async function deleteBatchById(id: string) {
  const res = await client.delete(`/batches/${id}`)
  return res.data
}

async function postBatchConfirm(id: string) {
  const res = await client.post(`/batches/${id}/confirm`, {})
  return res.data
}

async function postBatchesData(
  id: string,
  mobileNumbers: string[],
  scheduledDate: Date
): Promise<BatchDataWithBatch[]> {
  const res = await client.post(`/batches/${id}/data`, {
    mobileNumbers,
    scheduledDate,
  })
  return res.data
}

async function getBatchesWithBatchData(
    collectionAddress: string,
    orderBy = "ASC",
    sortBy = "createdAt"
  ): Promise<BatchesWithBatchData[]> {
  const res = await client.get(`/batches/batch-data`, {
    params: {
      collectionAddress,
      sortBy,
      orderBy,
    }
  })
  return res.data
}

async function getBatchesData(id: string): Promise<BatchData[]> {
  const res = await client.get(`/batches/${id}/data`)
  return res.data
}

async function getMedatada(url: string): Promise<any> {
  const res = await client.get(url)
  return res.data
}

const NFTServiceAPI = {
  getBatches,
  getBatchById,
  deleteBatchById,
  postBatchConfirm,
  postBatchesData,
  getBatchesData,
  getCollection,
  getMedatada,
  getBatchesWithBatchData
}

export default NFTServiceAPI
