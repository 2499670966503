import { Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper"
import Link from "@mui/material/Link"
import Button from "@mui/material/Button"
import CollectionAndTokenDetailTable, { TokenDetail } from '@components/table/collection-token-detail-table';
import Papa, { ParseResult } from 'papaparse';
import { useDropzone } from 'react-dropzone';
import { postGetPresignedUrls, postUploadedConfirmation, putUploadFile } from 'src/api/s3';
import { acceptStyle, baseStyle, focusedStyle, rejectStyle } from 'src/styles/dropzone';
import { useRecoilState } from 'recoil';
import { isLoadingState } from 'src/recoil';

const csvFieldConfig = [
  'image',
  'animation',
  'name',
  'description',
  'max_supply',
  'phone'
];

function FastCollectionCreation({ title }: { title?: string }) {
  const { collectionId } = useParams();

  const [, setIsLoading] = useRecoilState<boolean>(isLoadingState);

  const [dropFiles, setDropFiles] = React.useState<File[]>([]);
  const [csvFile, setCsvFile] = React.useState<File[]>([]);
  const [imageFiles, setImageFiles] = React.useState<File[]>([]);
  const [tokenTableData, setTokenTableData] = React.useState<TokenDetail[]>([]);
  const [error, setError] = React.useState<unknown>(false);

  React.useEffect(() => {
    document.title = title ? `Business Care ${'- ' + title}` : 'Business Care';
  }, [title]);

  const handleConfirm = async () => {
    setIsLoading(true);

    try {
      // Get S3's presigned URL
      const presignedURLs = await postGetPresignedUrls({
        collectionAddress: collectionId!,
        fileNames: [...(dropFiles.map(file => file.name))]
      });

      // Upload to S3
      const putUploadFilePromise = dropFiles.map(file => putUploadFile(presignedURLs.preSignedUrl[file.name], file));
      await Promise.all(putUploadFilePromise);

      // Uploaded confirmation
      await postUploadedConfirmation({
        key: `${presignedURLs.key}/${dropFiles.find(file => file.name.includes('.csv'))?.name}`,
        collectionAddress: collectionId!
      });
      window.location.href = `/fastcollection/${collectionId!}`;
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleResetUpload = () => {
    setTokenTableData([]);
    setCsvFile([]);
    setImageFiles([]);
  };

  React.useEffect(() => {
    function findImageByName(images: File[], name: string): File | undefined {
      return images.find(image => image.name.includes(name));
    }

    const tokens: TokenDetail[] = csvFile.map((row: any) => {
      return ({
        media_preview: findImageByName(imageFiles, row.image)!,
        nft_name: row.name,
        description: row.description,
        owner: row.phone
      })
    });
    setTokenTableData(tokens);

  }, [csvFile, imageFiles]);

  function Dropzone(props: any) {
    const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone();

    const style = useMemo(() => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }), [
      isFocused,
      isDragAccept,
      isDragReject
    ]);

    const imageFiles: File[] = [];

    function checkAcceptedFilesError(acceptedFiles: File[]) {
      // Empty directory
      if (acceptedFiles.length === 0) throw Error('Empty folder');
      // .csv isn't exist
      if (!acceptedFiles.find(file => file.name.includes('.csv'))) throw Error('.csv file isn\'t exist');
      // Got .csv more than 1 file
      if (acceptedFiles.filter(file => file.name.includes('.csv')).length > 1) throw Error('Got .csv more than 1 file');
    };

    // function checkCsvFilesError(csv: ParseResult<any>) {
    //   // .csv's field isn't correct
    //   if (csv.meta.fields!.every(field => csvFieldConfig.includes(field))) throw Error('.csv\'s field isn\'t correct');
    //   // .csv's records are not contain all
    //   // if (csv.data!.every(record => record[csvFieldConfig[0]].includes(imageFiles.map(file => file.name)))) console.log('.csv\'s records are not contain all');
    // };
  
    React.useEffect(() => {
      try {
        if (acceptedFiles.length !== 0) {
          checkAcceptedFilesError(acceptedFiles);
          setError(false);

          setDropFiles(acceptedFiles);
          acceptedFiles.forEach((file: File) => {
            // csv
            if (file.name.includes('.csv')) {
              Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: (results: ParseResult<any>) => {
                  setCsvFile(results.data);
                },
              });
            // Image
            } else {
              imageFiles.push(file);
            }
          });
          setImageFiles(imageFiles);
        }
      } catch (err) {
        setError(err);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);
  
    return (
      <section className="container">
        <div {...getRootProps({ style })}>
          <input data-testid="dropzone-input" {...getInputProps()} directory="" webkitdirectory="" />
          <p>Drag & drop folder here</p>
        </div>
      </section>
    );
  }

  return (
    <div id="detail">
      <main style={{ padding: "24px" }}>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
        >
          FAST COLLECTION
        </Typography>

        <div className="space" />

        <div style={{ textAlign: 'right' }}>
          <Link
            href={`/NFT-Fast-Drop-Template.csv`}
          >
            Download CSV Template
          </Link>
        </div>

        <div className="space" />

        <Paper variant="outlined" style={{ padding: '20px', width: '100%' }} id="drop-file">
          <div>Upload CSV</div>
          <div className="space" />

          <Dropzone />

          <div className="space" />

          <div style={{ textAlign: 'right' }}>
            <Button variant="outlined" onClick={handleResetUpload} data-testid="drop-file-reset">Reset</Button>
          </div>
        </Paper>

        <div className="space" />

        {error ? (
          <div style={{ textAlign: 'center' }}>
            <p>{`${error}`}</p>
            <p>Please Upload Again</p>
          </div>
        ) : (
          <>
            <Typography
              component="h5"
              variant="h6"
              color="inherit"
              align="left"
              noWrap
              sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
            >
              Token
            </Typography>

            <div className="space" />

            <CollectionAndTokenDetailTable showColumns={[
              'media_preview',
              'nft_name',
              'description',
              'owner'
            ]} data={tokenTableData} />
          </>
        )}

        <div className="space" />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            href={`/fastcollection/${collectionId}`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" className="btm-space">
              CANCEL
            </Button>
          </Link>
          <Button variant="contained" className="btm-space" disabled={!collectionId || !error ? false : true} onClick={handleConfirm}>
            CONFIRM
          </Button>
        </div>

      </main>
    </div>
  );
}

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

export default FastCollectionCreation;
