import MobileNoTable from '../components/mobile-no-table';
import TokenInfoBox from '../components/token-info-box';
import { Avatar, Paper, Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Api, { Batch, Collection } from '../api/NFTServiceAPI/api';

function Result({ title }: { title?: string }) {
  const { collectionId, batchId } = useParams();

  const [batch, setBatch] = React.useState<Batch>();
  const [collection, setCollection] = React.useState<Collection>();
  const [metadata, setMetadata] = React.useState<any>();
  const [batchesData, setBatchesData] = React.useState<any>();

  React.useEffect(() => {
    document.title = title ? `Business Care ${'- ' + title}` : 'Business Care';
  }, [title]);

  React.useEffect(() => {
    async function fetchAPI() {
      const batch = await Api.getBatchById(batchId!);
      const collection = await Api.getCollection(collectionId!);
      const batchesData = await Api.getBatchesData(batchId!);
      const { data: metadata } = await axios.get(
        collection.contractLevelMetadataURI
      );

      setBatch(batch);
      setCollection(collection);
      setBatchesData(batchesData);
      setMetadata(metadata);
    };

    if (collectionId && batchId) {
      fetchAPI();
    }

  }, [collectionId, batchId]);

  return (
    <div id="result">
      <main style={{ padding: "24px" }}>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
          className="page-title"
        >
          All Batches
        </Typography>

        <Paper
          elevation={2}
          style={{ padding: "15px", display: "flex", alignItems: "center" }}
        >
          <Avatar
            style={{ marginRight: "15px" }}
            imgProps={{ style: { objectFit: "contain" } }}
            src={metadata ? metadata.image : ''}
          />
          <div>
            <div>{metadata?.name}</div>
            <div style={{ fontSize: "14px " }}>{collection?.address}</div>
          </div>
        </Paper>

        <div className="space" />

        <TokenInfoBox
          info={batch ? [
            { number: batch.maxSupply, title: "Token Supply" },
            { number: batch.startToken, title: "Start Token" },
            {
              number: batch.maxSupply + batch.startToken - 1,
              title: "End Token",
            },
          ] : []}
        />
        <div className="space" />
        <div>
          <span style={{ color: "#51A9F6" }}>Overview</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Fill-in drop</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Saved and summary</span>
          <span> | </span>
          <span style={{ color: "#141312" }}>Result</span>
        </div>
        
        <div className="space" />

        <div>Drop Result</div>
        <MobileNoTable showColumns={['mobile_no', 'status', 'reason']} data={batchesData} />

      </main>
    </div>
  );
}

export default Result;
