import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper"
import Avatar from "@mui/material/Avatar"
import Link from "@mui/material/Link"
import Button from "@mui/material/Button"
import TokenInfoBox from '../components/token-info-box';
import DetailTable from '../components/detail-table';
import axios from 'axios';
import Api, { Batch, Collection } from '../api/NFTServiceAPI/api';

function Detail({ title }: { title?: string }) {
  const { collectionId, batchId } = useParams();

  const [batch, setBatch] = React.useState<Batch>();
  const [collection, setCollection] = React.useState<Collection>();
  const [metadata, setMetadata] = React.useState<any>();

  React.useEffect(() => {
    document.title = title ? `Business Care ${'- ' + title}` : 'Business Care';
  }, [title]);

  React.useEffect(() => {
    async function fetchAPI() {
      const batchResponse = await Api.getBatchById(batchId!);
      const collectionResponse = await Api.getCollection(collectionId!);

      const { data: metadata } = await axios.get(
        collectionResponse.contractLevelMetadataURI
      );

      setBatch(batchResponse);
      setCollection(collectionResponse);
      setMetadata(metadata);
    };

    if (collectionId && batchId) {
      fetchAPI();
    }

  }, [collectionId, batchId]);

  return (
    <div id="detail">
      <main style={{ padding: "24px" }}>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
        >
          All Batches
        </Typography>

        <Paper
          elevation={2}
          style={{ padding: "15px", display: "flex", alignItems: "center" }}
        >
          <Avatar
            style={{ marginRight: "15px" }}
            imgProps={{ style: { objectFit: "contain" } }}
            src={metadata ? metadata.image : ''}
          />
          <div>
            <div>{metadata?.name}</div>
            <div style={{ fontSize: "14px " }}>{collection?.address}</div>
          </div>
        </Paper>

        <div className="space" />

        <TokenInfoBox
          info={batch ? [
            { number: batch.maxSupply, title: "Token Supply" },
            { number: batch.startToken, title: "Start Token" },
            {
              number: batch.maxSupply + batch.startToken - 1,
              title: "End Token",
            },
          ] : []}
        />
        <div className="space" />
        <div>
          <span style={{ color: "#141312" }}>Overview</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Fill-in drop</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Saved and summary</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Result</span>
        </div>

        <div className="space" />

        {batch && collection ? (
          <DetailTable
            id={batch.id}
            description={batch.description}
            maxSupply={batch.maxSupply}
            collectionName={collection.name}
            animationUrl={batch.mediaPreview}
            nftName={batch.nftName}
            properties={JSON.stringify(batch.properties)}
          />
        ) : ''}

        <div className="space" />

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Link
            href={`/${collectionId}`}
            style={{ textDecoration: "none" }}
          >
            <Button variant="outlined" className="btm-space" disabled={collectionId ? false : true}>
              CANCEL
            </Button>
          </Link>
          <Link href={`/${collectionId}/${batchId}/fillindrop`}>
            <Button variant="contained" className="btm-space" disabled={collectionId && batchId ? false : true}>
              ADD DETAIL
            </Button>
          </Link>
        </div>
      </main>
    </div>
  );
}

export default Detail;
