import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { isLoadingState } from 'src/recoil';
import { useRecoilState } from 'recoil';

export default function Loading() {
  const [isLoading] = useRecoilState(isLoadingState);

  React.useEffect(() => {

  }, []);

  return (
    <div className={isLoading ? "loading" : "loading disable"} >
      <CircularProgress className="circular-loading" />
    </div>
  );
}