import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DropzoneArea } from "material-ui-dropzone";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextField from '@mui/material/TextField';
import MobileNoTable from './mobile-no-table';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import { BatchData } from 'src/api/NFTServiceAPI/api';
import { BatchPayload } from 'src/pages/FillInDrop';

dayjs().format()

const theme = createTheme();

export default function FillInDropDetail(props: { setPayload: React.Dispatch<React.SetStateAction<BatchPayload | undefined>>, payload: BatchPayload | undefined }) {
  const { setPayload, payload } = props;

  const [files, setFiles] = React.useState([]);
  const [value, setValue] = React.useState<Date | null>();
  const [mobileNo, setMobileNo] = React.useState<BatchData[]>([]);

  const handleDateChange = (newValue: Date | null) => {
    setValue(newValue);
  };

  const handleChange = (files: any) => {
    setFiles(files);
  };

  const handleResetUpload = () => {
    setMobileNo([]);
  }

  React.useEffect(() => {
    if (files.length !== 0) {
      Papa.parse(files[0], {
        header: false,
        skipEmptyLines: true,
        complete: (results: { data: string[] }) => {
          const data = results.data.map(no => ({ mobileNumber: no[0] }));
          setMobileNo(data as BatchData[]);
          setPayload({
            ...payload,
            mobile_no: results.data.map(no => (no[0])) as string[]
          });
        },
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  React.useEffect(() => {
    setPayload({
      ...payload,
      airdrop_date: value!,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="space" />

      <Paper variant="outlined" style={{ padding: '20px', width: '50vw' }} id="drop-file">
        <div>Upload CSV Airdrop</div>
        <DropzoneArea
          onChange={handleChange}
          acceptedFiles={['text/csv']}
          showFileNamesInPreview={false}
          showPreviews={false}
          showPreviewsInDropzone={false}
          filesLimit={1}
        />

        <div>
          <Button variant="outlined" onClick={handleResetUpload} id="drop-file-reset">Reset</Button>
        </div>
      </Paper>

      <div className="space" />

      <div style={{ marginBottom: '10px' }}>When will this phase starts?</div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          value={value}
          onChange={handleDateChange}
          renderInput={(params) => <TextField style={{ width: '50vw' }} {...params} />}
        />
      </LocalizationProvider>

      <div className="space" />

      <div style={{ marginBottom: '10px' }}>Receivers</div>
      <div style={{ width: '50vw' }}>
        <MobileNoTable showColumns={['mobile_no']} data={mobileNo} />
      </div>
      
    </ThemeProvider>
  );
}