import DetailTable from '../components/detail-table';
import ErrorSnackbar from '../components/error-snackbar';
import MobileNoTable from '../components/mobile-no-table';
import TokenInfoBox from '../components/token-info-box';
import { LoadingButton } from '@mui/lab';
import { Avatar, Button, Link, Paper, Typography } from '@mui/material';
import axios, { AxiosError } from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Api, { Batch, Collection } from '../api/NFTServiceAPI/api';

function Summary({ title }: { title?: string }) {
  const { collectionId, batchId } = useParams();

  const [batch, setBatch] = React.useState<Batch>();
  const [collection, setCollection] = React.useState<Collection>();
  const [metadata, setMetadata] = React.useState<any>();
  const [batchesData, setBatchesData] = React.useState<any>();

  const [isConfirm, setIsConfirm] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const childFunc: { current: any } = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    document.title = title ? `Business Care ${'- ' + title}` : 'Business Care';
  }, [title]);

  React.useEffect(() => {
    async function fetchAPI() {
      const batch = await Api.getBatchById(batchId!);
      const collection = await Api.getCollection(collectionId!);
      const batchesData = await Api.getBatchesData(batchId!);
      const { data: metadata } = await axios.get(
        collection.contractLevelMetadataURI
      );

      setBatch(batch);
      setCollection(collection);
      setBatchesData(batchesData);
      setMetadata(metadata);
    };

    if (collectionId && batchId) {
      fetchAPI();
    }

  }, [collectionId, batchId]);

  const handleConfirm = async () => {
    setIsConfirm(true);

    try {
      await Api.postBatchConfirm(batch!.id);
      setIsConfirm(false);
      window.location.href = `/${collection!.address}/${batch!.id}/result`;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setIsConfirm(false);
        setErrorMessage((error.response?.data as any).message);
        childFunc.current();
      } else {
        setErrorMessage("Different error than axios");
      }
    }
    
  }

  return (
    <div id="summary">
      <main style={{ padding: "24px" }}>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
        >
          All Batches
        </Typography>

        <Paper
          elevation={2}
          style={{ padding: "15px", display: "flex", alignItems: "center" }}
        >
          <Avatar
            style={{ marginRight: "15px" }}
            imgProps={{ style: { objectFit: "contain" } }}
            src={metadata ? metadata.image : ''}
          />
          <div>
            <div>{metadata?.name}</div>
            <div style={{ fontSize: "14px " }}>{collection?.address}</div>
          </div>
        </Paper>

        <div className="space" />

        <TokenInfoBox
          info={batch ? [
            { number: batch.maxSupply, title: "Token Supply" },
            { number: batch.startToken, title: "Start Token" },
            {
              number: batch.maxSupply + batch.startToken - 1,
              title: "End Token",
            },
          ] : []}
        />
        <div className="space" />

        <ErrorSnackbar message={errorMessage} childFunc={childFunc} />

        {batch && collection ? (
          <DetailTable
            id={batch.id}
            description={batch.description}
            maxSupply={batch.maxSupply}
            collectionName={collection.name}
            animationUrl={batch.mediaPreview}
            nftName={batch.nftName}
            properties={JSON.stringify(batch.properties)}
          />
        ) : ''}

        <div className="space" />
        
        <div>
          <span style={{ color: "#51A9F6" }}>Overview</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Fill-in drop</span>
          <span> | </span>
          <span style={{ color: "#141312" }}>Saved and summary</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Result</span>
        </div>

        <div className="space" />

        <Paper variant="outlined" style={{ padding: '20px' }}>
          <div>Campaign: New sign up</div>
          
          <MobileNoTable showColumns={['mobile_no', 'start_date']} data={batchesData} startDate={batch ? batch!.scheduledDate : undefined} />
        </Paper>

        <div className="space" />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link href={batch && collection ? `/${collection!.address}/${batch!.id}/fillindrop` : ''}>
            <Button variant="outlined" className="btm-space" disabled={isConfirm || (!collection && !batch)}>
              Edit
            </Button>
          </Link>
          <LoadingButton
            color="primary"
            onClick={handleConfirm}
            loading={isConfirm}
            loadingPosition="start"
            startIcon={<div />}
            variant="contained"
            className="btm-space"
          >
            Confirm
          </LoadingButton>
        </div>

      </main>
    </div>
  );
}

export default Summary;
