import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function ErrorSnackbar(props: { message: string, childFunc: React.MutableRefObject<any> }) {
  const { message, childFunc } = props;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useEffect(() => {
    childFunc.current = handleClick
  });

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} id="error-snackbar">
      <Alert severity="error" onClose={handleClose} sx={{ width: '100%' }}>{message}</Alert>
    </Snackbar>
  );
}