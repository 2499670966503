import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import keycloak from '../Keycloak';
import jwt_decode from 'jwt-decode';

export default function Header() {
  const [profile, setProfile] = React.useState({preferred_username: ""})

  const handleLogout = async () => {
    localStorage.removeItem('accessToken');
    keycloak!.logout();
  };

  React.useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const decoded = jwt_decode(token as string);
    setProfile(decoded as any);
  }, []);

  return (
    <React.Fragment>
      <Toolbar id="header">
        <a href="/">
          <div className='logo' style={{ backgroundImage: `url(/abc-logo.png)` }} />
        </a>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1 }}
          style={{ marginLeft: '10px' }}
        >
          BUSINESS CARE
        </Typography>

        <Avatar id="avatar">{(profile.preferred_username || 'BC')!.charAt(0).toUpperCase()}</Avatar>
        <div id="username" className="space-vertical" style={{ marginLeft: '7px' }}>{profile.preferred_username || ''}</div>

        <Button id="btn logout" variant="outlined" size="small" onClick={handleLogout} style={{ marginLeft: '15px' }}>
          Log Out
        </Button>
      </Toolbar>
    </React.Fragment>
  );
}