import React from 'react';
import { Routes, Route } from "react-router-dom";
import './App.scss';
import Header from './components/header';
import Home from './pages/Home';
import Container from "@mui/material/Container"
import Batches from './pages/Batches';
import keycloak from './Keycloak';
import Detail from './pages/Detail';
import FillInDrop from './pages/FillInDrop';
import Result from './pages/Result';
import Summary from './pages/Summary';
import FastCollectionDetail from './pages/fast-collection/Detail';
import FastCollectionCreation from './pages/fast-collection/Create';
import Loading from '@components/loading';

function App() {
  const [accessToken, setAccessToken] = React.useState<string>();

  React.useEffect(() => {
    async function initKeycloak() {
      await keycloak.init({
        onLoad: 'login-required',
        checkLoginIframe: false,
        refreshToken: keycloak.refreshToken
      });

      if (keycloak!.authenticated) {
        localStorage.setItem('accessToken', keycloak.token!);
        setAccessToken(keycloak.token!);
      }
    };

    initKeycloak();
  }, []);

  return (
    <>
      <Loading />
      <Container maxWidth="lg">
        {accessToken ? 
        <>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/:collectionId" element={<Batches />} />
            <Route path="/:collectionId/:batchId/detail" element={<Detail title="Detail" />} />
            <Route path="/:collectionId/:batchId/fillindrop" element={<FillInDrop title="Fill In Drop" />} />
            <Route path="/:collectionId/:batchId/summary" element={<Summary title="Summary" />} />
            <Route path="/:collectionId/:batchId/result" element={<Result title="Result" />} />

            {/* Fast Collection */}
            <Route path="/fastcollection/:collectionId" element={<FastCollectionDetail title="Fast Collection" />} />
            <Route path="/fastcollection/:collectionId/add" element={<FastCollectionCreation title="Fast Collection Creation" />} />
          </Routes>
        </>
        : '' }
      </Container>
    </>
  );
}

export default App;
