import { Typography } from '@mui/material';
import React from 'react';
import { useParams } from "react-router-dom";
import Paper from "@mui/material/Paper"
import Avatar from "@mui/material/Avatar"
import Link from "@mui/material/Link"
import Button from "@mui/material/Button"
import TokenInfoBox from '../components/token-info-box';
import axios, { AxiosError } from 'axios';
import Api, { Batch, Collection } from '../api/NFTServiceAPI/api';
import ErrorSnackbar from '../components/error-snackbar';
import SaveIcon from '@mui/icons-material/Save';
import { LoadingButton } from "@mui/lab"
import FillInDropDetail from '../components/fill-in-drop-detail';

export interface BatchPayload {
  airdrop_date?: Date;
  mobile_no?: string[];
}

function FillInDrop({ title }: { title?: string }) {
  const { collectionId, batchId } = useParams();

  const [batch, setBatch] = React.useState<Batch>();
  const [collection, setCollection] = React.useState<Collection>();
  const [metadata, setMetadata] = React.useState<any>();

  React.useEffect(() => {
    document.title = title ? `Business Care ${'- ' + title}` : 'Business Care';
  }, [title]);

  React.useEffect(() => {
    async function fetchAPI() {
      const batchResponse = await Api.getBatchById(batchId!);
      const collectionResponse = await Api.getCollection(collectionId!);

      const { data: metadata } = await axios.get(
        collectionResponse.contractLevelMetadataURI
      );

      setBatch(batchResponse);
      setCollection(collectionResponse);
      setMetadata(metadata);
    };

    if (collectionId && batchId) {
      fetchAPI();
    }

  }, [collectionId, batchId]);

  const [isSaving, setIsSaving] = React.useState(false);
  const [payload, setPayload] = React.useState<BatchPayload>();
  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const childFunc: { current: any } = React.useRef<HTMLInputElement>();

  const handleSave = async () => {
    setIsSaving(true);

    try {
      await Api.postBatchesData(batch!.id, payload!.mobile_no!, payload!.airdrop_date!);
      setIsSaving(false);
      window.location.href = `/${collection!.address}/${batch!.id}/summary`;
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setIsSaving(false);
        setErrorMessage((error.response?.data as any).message);
        childFunc.current();
      } else {
        setErrorMessage("Different error than axios");
      }
    }
  };

  return (
    <div id="fillindrop">
      <main style={{ padding: "24px" }}>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
        >
          All Batches
        </Typography>

        <Paper
          elevation={2}
          style={{ padding: "15px", display: "flex", alignItems: "center" }}
        >
          <Avatar
            style={{ marginRight: "15px" }}
            imgProps={{ style: { objectFit: "contain" } }}
            src={metadata ? metadata.image : ''}
          />
          <div>
            <div>{metadata?.name}</div>
            <div style={{ fontSize: "14px " }}>{collection?.address}</div>
          </div>
        </Paper>

        <div className="space" />

        <TokenInfoBox
          info={batch ? [
            { number: batch.maxSupply, title: "Token Supply" },
            { number: batch.startToken, title: "Start Token" },
            {
              number: batch.maxSupply + batch.startToken - 1,
              title: "End Token",
            },
          ] : []}
        />
        <div className="space" />

        <ErrorSnackbar message={errorMessage} childFunc={childFunc} />

        <div>
          <span style={{ color: "#51A9F6" }}>Overview</span>
          <span> | </span>
          <span style={{ color: "#141312" }}>Fill-in drop</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Saved and summary</span>
          <span> | </span>
          <span style={{ color: "#51A9F6" }}>Result</span>
        </div>

        <div className="space" />

        <FillInDropDetail setPayload={(value) => setPayload(value)} payload={payload} />

        <div className="space" />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Link href={batch ? batch!.scheduledDate ? `/${collection?.address}/${batch!.id}/summary` : `/${collection?.address}/${batch!.id}/detail` : ''}>
            <Button variant="outlined" className="btm-space" disabled={isSaving || (!batch && !collection)}>Discard</Button>
          </Link>
          <LoadingButton
            color="primary"
            onClick={handleSave}
            loading={isSaving}
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="contained"
            className="btm-space"
          >
            Save
          </LoadingButton>
        </div>
      </main>
    </div>
  );
}

export default FillInDrop;
