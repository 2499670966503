import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface Attribute {
  trait_type: string
  value: any
}

export interface Metadata {
  description: string
  external_url: string
  image: string
  name: string
  animation_url: string
  attributes: Attribute[]
}

export default function DetailTable({
  id,
  maxSupply,
  animationUrl,
  collectionName,
  nftName,
  description,
  properties,
}: {
  id: string
  maxSupply: number
  animationUrl: string
  collectionName: string
  nftName: string
  description: string
  properties: string
}) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Token Supply</TableCell>
            <TableCell>Media Preview</TableCell>
            <TableCell>Collection Name</TableCell>
            <TableCell>NFT Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Properties</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell>{maxSupply}</TableCell>
            <TableCell>
              <img style={{ width: "70px" }} src={animationUrl} alt={description} />
            </TableCell>
            <TableCell>{collectionName}</TableCell>
            <TableCell>{nftName}</TableCell>
            <TableCell>{description}</TableCell>
            <TableCell>{properties}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}
