import axios from 'axios';
import client from '..';

// API /batches/preSignedUrl
export interface PresignedUrlRequest {
  collectionAddress: string;
  fileNames: string[];
}

export interface PresignedUrlResponse {
  key: string;
  preSignedUrl: {
    [key: string]: string;
  };
}

// API /batches/complete-upload
export interface CompleteUploadRequest {
  collectionAddress: string,
	key: string,
}

export async function postGetPresignedUrls(request: PresignedUrlRequest): Promise<PresignedUrlResponse> {
  const response = await client.post(`/batches/pre-signed-url`, request);
  return response.data;
}

export async function putUploadFile(url: string, file: File): Promise<any> {
  return await axios.put(url, file, { headers: { 'content-type': file.type }});
}

export async function postUploadedConfirmation(request: CompleteUploadRequest): Promise<any> {
  const response = await client.post(`/batches/complete-upload`, request);
  return response.data;
}
