import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Chip from '@mui/material/Chip';
import dayjs from 'dayjs';
import { BatchData } from 'src/api/NFTServiceAPI/api';

interface Column {
  id: 'mobile_no' | 'start_date' | 'status' | 'reason';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: string) => any;
  isShow: boolean;
}

function createData(
  mobile_no: string,
  start_date: string,
  status: null | string | JSX.Element,
  reason: string | null
) { return { mobile_no, start_date, status, reason }; };

export default function MobileNoTable(props: { showColumns: string[], data: BatchData[], startDate?: string }) {
  const { showColumns, data = [], startDate } = props;

  const [page, setPage] = React.useState(0);
  // const [statusAmount, setStatusAmount] = React.useState<{ success: number | string, failed: number | string }>();
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // const StatusSummany = (props: { success: string | number | 0, failed: string | number | 0 }) => {
  //   if (showColumns.includes('status')) {
  //     return (
  //       <div>
  //         <div>Success: {props.success || ''}</div>
  //         <div>Failed: {props.failed || ''}</div>
  //       </div>
  //     )
  //   } else return '';
  // }

  const StatusChip = (props: { status: string} ) => {
    switch(props.status) {
      case 'SCHEDULED':
      case 'SENT':
      case 'PROCESSING':
        return <Chip label={props.status} color="success" />
      case 'ERROR':
        return <Chip label="Failed" color="error" />
      default:
        return <Chip label="Unknown" color="warning" />
    }
  }

  const columns: readonly Column[] = [
    { id: 'mobile_no', label: 'Mobile No.', minWidth: 170, isShow: true },
    { id: 'start_date', label: 'Start Date', minWidth: 100, isShow: false },
    {
      id: 'status',
      label: 'Status',
      minWidth: 170,
      format: (value: string) => <StatusChip status={value} />,
      isShow: false
    },
    {
      id: 'reason',
      label: 'Reason',
      minWidth: 170,
      isShow: false
    }
  ];

  React.useEffect(() => {
    setRows(data.map(i => createData(
      i.mobileNumber,
      dayjs(startDate!).format('DD/MM/YYYY HH:mm:ss'),
      i.status,
      i.reason
    )));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // React.useEffect(() => {
  //   setStatusAmount({
  //     success: data.filter(row => row.status === 'Sent').length,
  //     failed: data.filter(row => row.status === 'Failed').length
  //   });
  // }, [data]);

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" id="mobile-no-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (showColumns.includes(column.id)) {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    )
                  } else return ''
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.mobile_no} id={row.code}>
                      {columns.map((column) => {
                        if (showColumns.includes(column.id)) {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        } else return ''
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

      </Paper>

      {/* <StatusSummany success={statusAmount?.success} failed={statusAmount?.failed} /> */}
    </div>
  );
}