import BatchesTable, { BatchTableProps } from '../components/batches-table';
import { Typography } from '@mui/material';
import axios from 'axios';
import React from 'react';
import { useParams } from 'react-router-dom';
import Api, { Batch } from '../api/NFTServiceAPI/api';

function Batches({ title }: { title?: string }) {
  const { collectionId } = useParams();

  const [data, setData] = React.useState<BatchTableProps[]>();

  React.useEffect(() => {
    document.title = title ? `Business Care ${'- ' + title}` : 'Business Care';
  }, [title]);

  React.useEffect(() => {
    async function fetchAPI(collectionId: string) {
      const collection = await Api.getCollection(collectionId);
      const { data: metadata } = await axios.get(
        collection.contractLevelMetadataURI
      );
      const batches: Batch[] = await Api.getBatches(collectionId);

      setData(
        batches.map(batch => ({
          id: batch.id,
          symbol: metadata.image,
          bu_name: collection.ownerName,
          media_preview: batch.mediaPreview,
          collection_name: collection.name,
          nft_name: batch.nftName,
          description: batch.description,
          status: batch.confirmedAt ? 'Confirmed' : batch.scheduledDate ? 'Saved' : 'No Drop',
          remark: batch.remark
        }))
      )
    };

    if (collectionId) {
      fetchAPI(collectionId)
    }
  }, [collectionId]);

  return (
    <div id="batches">
      <main style={{ padding: "24px" }}>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
        >
          All Batches
        </Typography>

        <BatchesTable data={data} />
      </main>
    </div>
  );
}

export default Batches;
