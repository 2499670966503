import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import dayjs from 'dayjs'

export interface CollectionDetail {
  symbol: string;
  bu_name: string;
  attribute: string;
  collection_name: string;
};

export interface TokenDetail {
  media_preview: File | Blob | string;
  nft_name: string;
  description: string;
  token_id?: string;
  status?: string;
  reason?: string;
  owner: string;
  timestamp?: string;
};

interface Column {
  id: 'symbol' | 'bu_name' | 'attribute' | 'collection_name' | 'media_preview' | 'nft_name' | 'description' | 'token_id' | 'owner' | 'status' | 'reason' | 'timestamp',
  label: string;
  minWidth?: number;
  align?: 'center';
  format?: (value: string) => any;
}

const toBase64 = (file: File | Blob) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file as Blob);
  reader.onload = () => resolve(reader.result);
  reader.onerror = error => reject(error);
});

export default function CollectionAndTokenDetailTable(props: { showColumns: string[], data: CollectionDetail[] | TokenDetail[], showPagination?: boolean }) {
  const { showColumns, data = [], showPagination = true } = props;

  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState<any[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const columns: readonly Column[] = [
    // Collection
    {
      id: 'symbol',
      label: 'Symbol',
      minWidth: 100,
      // eslint-disable-next-line jsx-a11y/alt-text
      format: (imageUrl) => <img src={imageUrl} style={{ width: '100px', height: '100px' }} />
    },
    { id: 'bu_name', label: 'BU Name', minWidth: 100 },
    { id: 'attribute', label: 'Attribute', minWidth: 100 },
    { id: 'collection_name', label: 'Collection Name', minWidth: 100 },

    // Token
    {
      id: 'media_preview',
      label: 'Media Preview',
      minWidth: 100,
      // eslint-disable-next-line jsx-a11y/alt-text
      format: (imageBase64) => <img src={imageBase64} style={{ width: '100px', height: '100px' }} />
    },
    { id: 'nft_name', label: 'NFT Name', minWidth: 100 },
    { id: 'description', label: 'Description', minWidth: 100 },
    { id: 'token_id', label: 'Token ID', minWidth: 100 },
    { id: 'owner', label: 'Owner', minWidth: 100 },
    { id: 'status', label: 'Status', minWidth: 100 },
    { id: 'reason', label: 'Reason', minWidth: 100 },
    {
      id: 'timestamp',
      label: 'Timestamp',
      minWidth: 170,
      format: (value: string) => value
    }
  ];

  function isCollectionDetail(data: CollectionDetail[] | TokenDetail[]): data is CollectionDetail[] {
    return (data[0] as CollectionDetail).collection_name !== undefined;
  }

  React.useEffect(() => {
    (async () => {
      if (data.length !== 0) {
        if (isCollectionDetail(data)) {
          setRows(data);
        } else {
          setRows(await Promise.all(data.map(async (rowData: any) => {
            return ({
              media_preview: typeof((rowData as TokenDetail).media_preview) === 'string' ? (rowData as TokenDetail).media_preview : await toBase64((rowData as TokenDetail).media_preview as File),
              nft_name: rowData.nft_name,
              description: rowData.description,
              owner: rowData.owner,
              token_id: rowData.token_id,
              status: rowData.status,
              reason: rowData.reason,
              timestamp: dayjs(rowData.timestamp).format('DD/MM/YYYY HH:mm:ss')
            });
          })));
        }
      }
    })();
  }, [data]);

  return (
    <div>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table" id="mobile-no-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  if (showColumns.includes(column.id)) {
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    )
                  } else return ''
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length !== 0 ? '' : <TableCell colSpan={columns.length} align="center">No Data</TableCell>}
              
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.mobile_no} id={row.code}>
                      {columns.map((column) => {
                        if (showColumns.includes(column.id)) {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        } else return ''
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {showPagination ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : ''}
      </Paper>

    </div>
  );
};