import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from "react-router-dom";
import { Link } from '@mui/material';
import Api from '../api/NFTServiceAPI/api';
import ErrorSnackbar from './error-snackbar';
import axios, { AxiosError } from 'axios';

export interface BatchTableProps {
  id: string,
  symbol: string,
  bu_name: string,
  media_preview: string,
  collection_name: string,
  nft_name: string,
  description: string,
  status: string,
  remark: null | string
}

function createData(
  id: string,
  symbol: string,
  bu_name: string,
  media_preview: string,
  collection_name: string,
  nft_name: string,
  description: string,
  status: string,
  remark: null | string
): BatchTableProps {
  return {
    id,
    symbol,
    bu_name,
    media_preview,
    collection_name,
    nft_name,
    description,
    status,
    remark
  };
}

export default function BatchesTable(props: { data: BatchTableProps[] | undefined }) {
  const { data } = props;
  const { collectionId } = useParams();

  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [rows, setRows] = React.useState<BatchTableProps[]>();

  const childFunc: { current: any } = React.useRef<HTMLInputElement>();

  React.useEffect(()=>{
    if (!data) {
      setRows([]);
    } else if (data!.length !== 0) {
      setRows(data!.map(i => createData(
        i.id,
        i.symbol,
        i.bu_name,
        i.media_preview,
        i.collection_name,
        i.nft_name,
        i.description,
        i.status,
        i.remark
      )));
    }
  }, [data]);

  const SeeDetailBtn = ({ status, batchId }: { status: string, batchId: string }): JSX.Element => {
    switch(status) {
      case 'No Drop':
        return (
          <Link href={`/${collectionId}/${batchId}/detail`}><Button size="small">See Detail</Button></Link>
        );
      case 'Saved':
        return (
          <Link href={`/${collectionId}/${batchId}/summary`}><Button size="small">See Detail</Button></Link>
        );
      case 'Confirmed':
        return (
          <Link href={`/${collectionId}/${batchId}/result`}><Button size="small">See Detail</Button></Link>
        );
      default:
        return <div></div>
    }
  };

  const handleDelete = async (batchId: string) => {
    try {
      await Api.deleteBatchById(batchId);
      setRows(rows!.filter(i => i.id !== batchId));
    } catch (err) {
      if (axios.isAxiosError(err)) {
        const error = err as AxiosError;
        setErrorMessage((error.response?.data as any).message);
        childFunc.current();
      } else {
        setErrorMessage("Different error than axios");
      }
    }
  };

  const TableBodyWithLogic = (): JSX.Element => {
    if (!rows) {
      return (
        <CircularProgress />
      );
    } else if (rows.length === 0) {
      return (
        <div>No Data</div>
      );
    } else {
      return (
        <TableBody>
          {rows!.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              id={row.id}
            >
              <TableCell><img style={{ width: '70px' }} src={row.symbol} alt={row.collection_name} /></TableCell>
              <TableCell>{row.bu_name}</TableCell>
              <TableCell><img style={{ width: '70px' }} src={row.media_preview} alt={row.description} /></TableCell>
              <TableCell>{row.collection_name}</TableCell>
              <TableCell>{row.nft_name}</TableCell>
              <TableCell>{row.description}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell align="center">
                <SeeDetailBtn status={row.status} batchId={row.id} />
                <Button size="small" color="error" onClick={() => handleDelete(row.id)}>Delete</Button>
              </TableCell>
              <TableCell>{row.remark}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      );
    }
  };

  return (
    <TableContainer component={Paper}>
      <ErrorSnackbar message={errorMessage} childFunc={childFunc} />
      <Table sx={{ minWidth: 650 }} aria-label="simple table" id="batch-table">
        <TableHead>
          <TableRow>
            <TableCell>Symbol</TableCell>
            <TableCell>BU (Owner) Name</TableCell>
            <TableCell>Media Preview</TableCell>
            <TableCell>Collection Name</TableCell>
            <TableCell>NFT Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Remark</TableCell>
          </TableRow>
        </TableHead>
        <TableBodyWithLogic />
      </Table>
    </TableContainer>
  );
}