import { Link, Typography } from '@mui/material';
import React from 'react';
import { useParams } from "react-router-dom";
import CollectionAndTokenDetailTable, { CollectionDetail, TokenDetail } from '@components/table/collection-token-detail-table';
import Api from '../../api/NFTServiceAPI/api';
import client, { axiosClient } from 'src/api';
import axios from 'axios';

function FastCollectionDetail({ title }: { title?: string }) {
  const { collectionId } = useParams();

  React.useEffect(() => {
    document.title = title ? `Business Care ${'- ' + title}` : 'Business Care';
  }, [title]);

  const [collectionTable, setCollectionTable] = React.useState<CollectionDetail[]>([]);
  const [tokenTable, setTokenTable] = React.useState<TokenDetail[]>([]);

  React.useEffect(() => {
    async function fetchAPI() {
      try {
        // Get collection
        const collectionResponse = await Api.getCollection(collectionId!);

        const { data: metadata } = await axiosClient.get(collectionResponse.contractLevelMetadataURI);

        setCollectionTable([{
          symbol: metadata.image,
          bu_name: collectionResponse.ownerName,
          attribute: JSON.stringify(metadata.attributes),
          collection_name: collectionResponse.name
        }])

        // Get token
        const batches = await Api.getBatchesWithBatchData(collectionId!);
        const tokenes = batches.map(batchDetail => ({
          media_preview: batchDetail.batch.mediaPreview,
          nft_name: batchDetail.batch.nftName,
          description: batchDetail.batch.description,
          token_id: batchDetail.tokenId,
          status: batchDetail.status === 'ERROR' ? 'FAILED' : batchDetail.status,
          reason: batchDetail.reason,
          owner: batchDetail.mobileNumber,
          timestamp: batchDetail.createdAt
        }));

        setTokenTable(tokenes);
      } catch (err) {
        console.log(err)
      }
    };

    if (collectionId) {
      fetchAPI();
    };
  }, [collectionId]);

  return (
    <div id="detail">
      <main style={{ padding: "24px" }}>
        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
        >
          COLLECTION
        </Typography>

        <div className="space" />
        
        {collectionId ? (
          <CollectionAndTokenDetailTable showColumns={['symbol', 'bu_name', 'attribute', 'collection_name']} data={collectionTable} showPagination={false} />
        ) : ''}

        <div className="space" />

        <Typography
          component="h5"
          variant="h6"
          color="inherit"
          align="left"
          noWrap
          sx={{ flex: 1, fontWeight: "bold", marginBottom: "20px" }}
        >
          Token
        </Typography>

        <div style={{ marginBottom: '20px' }}>
          Deployed | <Link href={`/fastcollection/${collectionId}/add`}>Add</Link>
        </div>

        {collectionId ? (
          <CollectionAndTokenDetailTable showColumns={[
            'media_preview',
            'nft_name',
            'description',
            'token_id',
            'status',
            'reason',
            'owner',
            'timestamp'
          ]} data={tokenTable} />
        ) : ''}

      </main>
    </div>
  );
}

export default FastCollectionDetail;
