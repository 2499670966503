import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

interface TokenInfoBoxType {
  number: number | string;
  title: string;
}

export default function TokenInfoBox(props: { info: TokenInfoBoxType[] }) {
  const { info } = props;

  return (
    <Grid container spacing={2} id="token-info-box">
      {info.map(i => (
        <Grid item xs={2} key={i.title}>
          <Paper variant="outlined" className="info">
            <div className="number">{i.number}</div>
            <div className="title">{i.title}</div>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
}